import React from 'react';
import './Privacidad.css';

const Privacidad = () => {
  return (
    <div className="privacidad-container">
      <h1 className="privacidad-title">Política de Privacidad de Creaciones Perla</h1>

      <p>
        En Creaciones Perla, valoramos tu privacidad y estamos comprometidos a proteger tus datos personales. Esta política de privacidad explica cómo recopilamos, usamos, y protegemos tu información personal.
      </p>

      <h2>1. Información que Recopilamos</h2>
      <h3>1.1 Información Proporcionada por el Usuario:</h3>
      <p>
        Recopilamos información personal que nos proporcionas directamente al registrarte, completar formularios, o interactuar con nuestro sitio web. Esto puede incluir tu nombre, dirección de correo electrónico, número de teléfono, dirección, y cualquier otra información que elijas compartir.
      </p>
      <h3>1.2 Información Recopilada Automáticamente:</h3>
      <p>
        Solamente si aceptas la política de privacidad de la empresa, recopliaremos información automáticamente sobre tu dispositivo y cómo interactúas con nuestro sitio web. Esto puede incluir tu dirección IP, tipo de navegador, páginas visitadas de nuestro sitio web, y tiempo de visita. Si no aceptas las políticas de privacidad, no se recopilaran datos sobre tu conducta en nuestro sitio web o tu dispositivo. Recuerda que los pedidos web requieren que el usuario introduzca algunos datos personales básicos, para realizar el envío a domicilio.
      </p>

      <h2>2. Uso de la Información</h2>
      <h3>2.1 Proveer y Mejorar Nuestros Servicios:</h3>
      <p>
        Utilizamos la información recopilada para proporcionarte nuestros servicios, personalizar tu experiencia en nuestro sitio, y mejorar nuestras ofertas.
      </p>
      <h3>2.2 Comunicaciones:</h3>
      <p>
        Podemos usar tu información para comunicarnos contigo sobre actualizaciones, ofertas especiales, y otras noticias relacionadas con Creaciones Perla que puedan ser de tu interés.
      </p>

      <h2>3. Compartir Información con Terceros</h2>
      <p>
        No vendemos, alquilamos ni compartimos tu información personal con terceros para sus propios fines. Esto no ocurre, bajo ninguna circunstancia.
      </p>

      <h2>4. Seguridad de la Información</h2>
      <h3>4.1 La seguridad es parte de nuestro servicio:</h3>
      <p>
        Implementamos medidas de seguridad técnicas y organizativas para proteger tu información personal contra el acceso no autorizado, alteración, divulgación o destrucción.
      </p>
      <h3>4.2 No aceptamos pagos online</h3>
      <p>
        Nuestro sitio web no acepta pagos online. Nunca envíes los datos de tu tarjetas o realices transferencias, a través de nuestro sitio web.
      </p>

      <h2>5. Tus Derechos</h2>
      <h3>5.1 Acceso y Corrección:</h3>
      <p>
        Tienes derecho a acceder a la información personal que tenemos sobre ti y a solicitar la corrección de datos inexactos.
      </p>
      <h3>5.2 Eliminación de Datos:</h3>
      <p>
        Puedes solicitar la eliminación de tu información personal, salvo que estemos obligados a conservarla por razones legales.
      </p>
      <p>
        Tiene el derecho de acceder en cualquier momento a los datos personales que sean recolectados y tratados por Creaciones Perla, conforme a lo establecido por la Ley N° 25.326 de Protección de Datos Personales.
      </p>
      <blockquote>
        “El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos de forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto, conforme a lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326.”
      </blockquote>

      <p>
        Si usted no está de acuerdo con los términos de nuestra Política de Privacidad, nuestros Términos y Condiciones y/o nuestra Política de Cookies, por favor no utilice los sitios web de Creaciones Perla ni proporcione sus datos personales a través de ellos.
      </p>

      <h2>6. Cookies y Tecnologías Similares</h2>
      <p>
        Utilizamos cookies y tecnologías similares (ej: almacenamiento en localhost) para mejorar tu experiencia en nuestro sitio web. Puedes configurar tu navegador para borrar estas formas de almacenamiento de datos, pero esto puede afectar la funcionalidad del sitio.
      </p>
    </div>
  );
};

export default Privacidad;
