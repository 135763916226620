import React, { useState } from 'react';
import './NewsLetter.css';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async () => {
    if (!email) {
      setMessage('Por favor ingresa un email válido.');
      return;
    }

    try {
      const response = await fetch('https://creacionesperla.com/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('¡Gracias por suscribirte!');
        setEmail('');
      } else {
        setMessage('Hubo un error al suscribirte. Inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      setMessage('Hubo un error al suscribirte. Inténtalo de nuevo más tarde.');
    }
  };

  return (
    <div className='newsletter'>
      <h1>Recibí ofertas exclusivas y descuentos en tu email</h1>
      <p>Suscribite a nuestro programa de clientes y descubrí todos los beneficios que tenemos para vos</p>
      <div className='input-container'>
        <input
          type="email"
          placeholder="Email de contacto"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleSubscribe}>Suscribirse</button>
      </div>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default NewsLetter;
