import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route, useLocation } from 'react-router-dom';
import { useState, useEffect} from 'react';
import Shop from './Pages/Shop';
import Schools from './Pages/Schools';
import Cart from './Pages/Cart';
import CartForm from './Components/CartForm/CartForm';
import LoginSignup from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
import Niveles from './Components/Niveles/Niveles';
import Gender from './Components/Gender/Gender';
import Identidad from './Components/Identidad/Identidad';
import Contacto from './Components/Contacto/Contacto';
import Preguntas from './Components/Preguntas/Preguntas';
import Companies from './Pages/Companies';
import Company from './Components/Company/Company';
import Kits from './Components/Kits/Kits';
import Loading from './Components/Loading/Loading';
import Privacidad from './Components/Privacidad/Privacidad';

function RouteTracker({ cookiesAccepted }) {
  const location = useLocation();

    // Función para convertir a formato DATETIME
    const toMySQLDateTime = (date) => {
      const pad = (n) => (n < 10 ? `0${n}` : n);
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };

  useEffect(() => {
    if (cookiesAccepted) {
      // Inicializar ID único y fecha si no existen en localStorage
      let clientId = localStorage.getItem('clientId');
      let initialDate = localStorage.getItem('registrationDate');
      if (!clientId || !initialDate) {
        clientId = crypto.randomUUID();
        initialDate = toMySQLDateTime(new Date());
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('registrationDate', initialDate);
      }

      // Actualizar rutas visitadas
      const visitedRoutes = JSON.parse(localStorage.getItem('visitedRoutes')) || [];
      visitedRoutes.push(location.pathname);
      localStorage.setItem('visitedRoutes', JSON.stringify(visitedRoutes));

      // Enviar datos al backend
      const sendRoutesToBackend = async () => {
        const payload = {
          id_dispositivo: clientId,
          fecha_registro_inicial: initialDate,
          rutas: visitedRoutes,
          fecha_de_actividad: toMySQLDateTime(new Date()),
        };

        try {
          await fetch('https://creacionesperla.com/api/clientes-web', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          });
        } catch (error) {
          console.error('Error al enviar rutas al backend:', error);
        }
      };

      sendRoutesToBackend();
    }
  }, [location, cookiesAccepted]);

  return null; // No renderiza nada
}


function App() {
  const [isLoading, setIsLoading] = useState(false); // Estado inicial para loading
  const [showCookiesNotice, setShowCookiesNotice] = useState(false); // Estado para el aviso de cookies
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookies = localStorage.getItem('cookies');
    if (cookies === null) {
      setShowCookiesNotice(true);
    } else {
      setCookiesAccepted(cookies === 'true');
    }
  }, []);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      setIsLoading(true); // Muestra el loading
      const timer = setTimeout(() => {
        setIsLoading(false); // Detiene el loading
        setShowCookiesNotice(true); // Muestra el aviso de cookies
        localStorage.setItem('hasVisited', 'true'); // Marca como visitado
      }, 2000);

      return () => clearTimeout(timer); // Limpia el temporizador
    }
  }, []); // Se ejecuta una vez al montar el componente

  const handleAcceptCookies = () => {
    setShowCookiesNotice(false); // Oculta el aviso de cookies
    setCookiesAccepted(true);
    localStorage.setItem('cookies', 'true');
  };

  const handleRejectCookies= () => {
    setShowCookiesNotice(false); // Oculta el aviso de cookies
    setCookiesAccepted(false);
    localStorage.setItem('cookies', 'false');
  };

  return (
    <div>
      {isLoading && <Loading />} {/* Muestra loading solo si está activo */}
      {!isLoading && (
        <>
          <BrowserRouter>
            <Navbar />
            <RouteTracker cookiesAccepted={cookiesAccepted} />
            <Routes>
              <Route path="/" element={<Shop />} />
              <Route path="/escolar" element={<Schools category="escolar" />} />
              <Route path="/escolar/:id" element={<Niveles />} />
              <Route path="/escolar/:id/:nivel/:sexo" element={<Gender />} />
              <Route path="/escolar/:id/kit/:sexo" element={<Kits />} />
              <Route path="/identidad" element={<Identidad />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/preguntas" element={<Preguntas />} />
              <Route path="/empresas" element={<Companies />} />
              <Route path="/empresas/:name" element={<Company />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/cart-form" element={<CartForm />} />
              <Route path="/login" element={<LoginSignup />} />
              <Route path="/privacidad" element={<Privacidad />} />
              <Route path="/eshop/schools" element={<Shop />} />
              <Route path="/eshop" element={<Shop />} />
            </Routes>
            <Footer />
          </BrowserRouter>

          {showCookiesNotice && (
            <div className="cookies-notice">
            <div>
              <strong>Política de Privacidad de Cookies</strong>
              <p>
                Nuestro sitio web usa cookies. Descubrí más sobre su funcionamiento y su uso por nuestra parte.{' '}
                <a href="/privacidad" target="_blank" rel="noopener noreferrer">
                  Hacé clic acá para más información.
                </a>
              </p>
            </div>
            <button onClick={handleAcceptCookies}>Aceptar</button>
            <button onClick={handleRejectCookies}>Rechazar</button>
          </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
