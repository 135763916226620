import React, { useContext, useState, useRef }from 'react'
import "./Navbar.css"
import logo from '../Assets/logo1.webp'
import cart_icon from '../Assets/cart_icon.webp'
import { Link } from 'react-router-dom'
import { ShopContext } from '../../Context/ShopContex'
import nav_dropdown from '../Assets/dropdown_icon.webp'
import whatsapp_icon from '../Assets/whatsapp_icon.webp'


const Navbar = () => {
    const [menu,setMenu]= useState("shop");
    const {getTotalCartItems} = useContext(ShopContext);
    const menuRef = useRef();

    const dropdown_toggle = (e)=>{
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open');
    }
    return (
        <div className='navbar'>
            <div className='nav-logo'>
                <img src={logo} alt="logo"/>
                <p>Creaciones Perla</p>
            </div>
            <img className='nav-dropdown' onClick={dropdown_toggle} src={nav_dropdown} alt="menu dropdown icon" />
            <ul ref={menuRef} className='nav-menu'>
                <li onClick={() => { setMenu("shop") }}>
                    <Link style={{ textDecoration: 'none' }} to='/'>Inicio</Link> 
                    {menu === "shop" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("escolar") }}>
                    <Link style={{ textDecoration: 'none' }} to='/escolar'>Uniformes <br /> Escolares</Link>
                    {menu === "escolar" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("empresas") }}>
                    <Link style={{ textDecoration: 'none' }} to='/empresas'>Uniformes <br /> Empresariales</Link>
                    {menu === "empresas" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("identidad") }}>
                    <Link style={{ textDecoration: 'none' }} to='/identidad'>¿Quienes <br /> somos?</Link>
                    {menu === "identidad" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("contacto") }}>
                    <Link style={{ textDecoration: 'none' }} to='/contacto'>Contacto</Link>
                    {menu === "contacto" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("preguntas") }}>
                    <Link style={{ textDecoration: 'none' }} to='/preguntas'>Preguntas <br /> frecuentes</Link>
                    {menu === "preguntas" ? <hr /> : <></>}
                </li>
            </ul>
            <div className='nav-login-cart'>
                {/* <Link style={{ textDecoration: 'none' }} to='/login'>
                    <button>Login</button>
                </Link> */}
                <Link style={{ textDecoration: 'none' }} to='/cart'>
                    <img src={cart_icon} alt="cart icon" />
                </Link>
                <nav className="nav-cart-count">{getTotalCartItems()}</nav>
            </div>
            <a
                href="https://api.whatsapp.com/send?phone=543515301676" // Reemplaza con tu número de WhatsApp
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-icon"
            >
                <img src={whatsapp_icon} alt="WhatsApp Icon" />
            </a>
        </div>
    );
}
export default Navbar