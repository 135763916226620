import React from 'react'
import {useState}from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import footer_logo from '../Assets/logo1.webp'
import instagram_icon from '../Assets/instagram_icon.webp'
import whatsapp_icon from '../Assets/whatsapp_icon.webp'
import facebook_icon from '../Assets/facebook_icon.webp'
import afip_icon from '../Assets/DATAWEB.webp'

const Footer = () => {
    const [menu,setMenu]= useState("shop");
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={footer_logo} alt="" />
            <p>Creaciones Perla</p>
        </div>
        <ul className="footer-links">
                <li onClick={() => { setMenu("shop") }}>
                    <Link style={{ textDecoration: 'none' }} to='/'>Inicio</Link> 
                    {menu === "shop" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("escolar") }}>
                    <Link style={{ textDecoration: 'none' }} to='/escolar'>Uniformes <br /> Escolares</Link>
                    {menu === "escolar" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("empresas") }}>
                    <Link style={{ textDecoration: 'none' }} to='/empresas'>Uniformes <br /> Empresariales</Link>
                    {menu === "empresas" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("identidad") }}>
                    <Link style={{ textDecoration: 'none' }} to='/identidad'>¿Quienes <br /> somos?</Link>
                    {menu === "identidad" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("contacto") }}>
                    <Link style={{ textDecoration: 'none' }} to='/contacto'>Contacto</Link>
                    {menu === "contacto" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("preguntas") }}>
                    <Link style={{ textDecoration: 'none' }} to='/preguntas'>Preguntas <br /> frecuentes</Link>
                    {menu === "preguntas" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("privacidad") }}>
                    <Link style={{ textDecoration: 'none' }} to='/privacidad'>Política de <br /> privacidad</Link>
                    {menu === "privacidad" ? <hr /> : <></>}
                </li>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <a href="https://www.instagram.com/creaciones_perla/" target="_blank" rel="noopener noreferrer">
                    <img src={instagram_icon} alt=""/>
                </a>
            </div>
            <div className="footer-icons-container">
                <a href="https://www.facebook.com/creaciones.perla" target="_blank" rel="noopener noreferrer">
                    <img src={facebook_icon} alt="" />
                </a>
            </div>
            <div className="footer-icons-container">
                <a href="https://api.whatsapp.com/send?phone=543515301676" target="_blank" rel="noopener noreferrer">
                    <img src={whatsapp_icon} alt="" />
                </a>
            </div>
        </div>
        <div className="afip-icon-container">
            <a href="http://qr.afip.gob.ar/?qr=6Q7O4kS444ph4pRteX-CQA,,">
                <img src={afip_icon} alt="" />
            </a>
        </div>
        <div className="footer-copyright">
            <hr />
            <p>Copyright @ 2024 - Todos los derechos reservados</p>
            <p className='atribution-p'>Instagram icon by Pixel perfect</p>
            <p className='atribution-p'>Facebook icon by Freepik</p>
            <p className='atribution-p'>Whatsapp icon by riajulislam</p>
        </div>
    </div>
  )
}
export default Footer