import React from 'react';
import './Loading.css'; // Importa los estilos
import logo from '../Assets/logo1.webp'

const Loading = () => {
  return (
    <div className="loading-overlay">
      <img src={logo} alt="Logo de la empresa" className="loading-logo" />
      <div className="loading-spinner"></div>
      <p className="loading-text">Cargando...</p>
    </div>
  );
};

export default Loading;
